import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaginationResponseBase } from '@models/pagination-response-base.model';
import { Filters } from '@shared/models/filters.model';
import { OrderBy, UserFields } from '@shared/models/request-common.model';
import { Observable, of } from 'rxjs';
import { APP_API_URL, IDS_API_URL } from '../injections';
import { UsersFullResponse } from '@shared/models/users-full-response.model';
import { Users } from '@shared/models/users.model';
import { map } from 'rxjs/operators';
import { ResponseBase } from '@shared/models/response-base.model';
import { GroupAssocRequest } from '@shared/models/group-assoc-request.model';
import { GroupResponse } from '@shared/models/group-response.model';

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_API_URL) private readonly appBaseUrl: string,
    @Inject(IDS_API_URL) private readonly baseUrl: string
  ) {}

  public permissions(): Observable<string[]> {
    return this.http.get<string[]>(`${this.appBaseUrl}/users/me/permissions`);
  }

  public getUserGroups(): Observable<GroupResponse[]> {
    return this.http.get<GroupResponse[]>(`${this.appBaseUrl}/users/me/group`);
  }

  pageQuery(
    filters: Filters[],
    sort: UserFields,
    order: OrderBy,
    page: number,
    size: number,
    includeGroups: boolean,
  ): Observable<HttpResponse<PaginationResponseBase<UsersFullResponse[]>>> {
    let params = new HttpParams();
    if (page !== undefined && page !== null) {
      params = params.set('page', page.toString());
    }
    if (size !== undefined && size !== null) {
      params = params.set('size', size.toString());
    }
    if (sort !== undefined && sort !== null) {
      params = params.set('sort', sort);
      params = params.set('order', order ? order : 'ASC');
    }
    if (filters !== undefined && filters !== null && filters.length > 0) {
      filters.forEach(f => (params = params.append('filter', JSON.stringify(f))));
    }
    if (includeGroups) {
      params = params.set('include', 'Groups');
    }
    return this.http
      .get<PaginationResponseBase<UsersFullResponse[]>>(`${this.baseUrl}/user`, {observe: 'response', params})
      .pipe(map((res: HttpResponse<PaginationResponseBase<UsersFullResponse[]>>) => res));
  }

  createGroupAssoc(userId: string, groupId: string): Observable<HttpResponse<ResponseBase<Users>>> {
    return this.http
      .post<ResponseBase<Users>>(`${this.baseUrl}/user/${userId}/group`, new GroupAssocRequest(groupId), { observe: 'response' })
      .pipe(map((res: HttpResponse<ResponseBase<Users>>) => res));
  }

  deleteGroupAssoc(userId: string, groupId: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.baseUrl}/user/${userId}/group/${groupId}`, { observe: 'response' });
  }
}

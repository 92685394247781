import { Action, createReducer, on } from '@ngrx/store';
import { IUserProfile } from '@shared/services/platform-api.service';
import { UserActions } from '../actions';


export const userFeatureKey = 'user';

export interface UserState {
  profile?: IUserProfile;
  loaded: boolean;
}

export const initialState: UserState = {
  profile: null,
  loaded: false,
};

const userReducer = createReducer(
  initialState,
  on(UserActions.GET_OR_LOAD_PROFILE, state => ({
    ...state
  })),
  on(UserActions.LOADED_PROFILE, (state, { profile }) => ({
    profile,
    loaded: true
  })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

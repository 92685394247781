import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { userFeatureKey, UserState } from '../reducers/user.reducer';
import { IUserProfile } from '@shared/services/platform-api.service';

const userStateSelector = createFeatureSelector<AppState, UserState>(userFeatureKey);

export const userProfile = createSelector(
  userStateSelector,
  (state: UserState) => (state ? state.profile : undefined)
);

export const userProfileLoaded = createSelector(
  userStateSelector,
  (state: UserState) => (state ? state.loaded : undefined)
);

export const userOrganization = createSelector(
  userProfile,
  (profile: IUserProfile) => (profile && profile.identity ? profile.identity.organisationName : undefined)
);

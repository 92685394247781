import { Action, createReducer, on } from '@ngrx/store';
import { PermissionActions } from '../actions';


export const permissionFeatureKey = 'permission';

export interface PermissionState {
  permissions?: string[];
  loaded: boolean;
}

export const initialState: PermissionState = {
  permissions: null,
  loaded: false,
};

const permissionReducer = createReducer(
  initialState,
  on(PermissionActions.GET_OR_LOAD, state => ({
    ...state
  })),
  on(PermissionActions.LOADED, (state, { permissions }) => ({
    permissions,
    loaded: true
  })),
);

export function reducer(state: PermissionState | undefined, action: Action) {
  return permissionReducer(state, action);
}

import { Injectable } from '@angular/core';
import cookie from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class LoginTrackingService {
  private readonly cookieName = 'total_visits';
  private counter: number;

  constructor() {
    const cookieValue = cookie.get(this.cookieName);
    this.counter = isNaN(parseInt(cookieValue, 10)) ? 0 : parseInt(cookieValue, 10);
  }

  isFirstLogin() {
    this.counter += 1;
    cookie.set(this.cookieName, this.counter);
    return this.counter === 1;
  }

  removeTracking() {
    cookie.remove(this.cookieName);
  }

  resetTracking() {
    cookie.set(this.cookieName, 0);
  }
}

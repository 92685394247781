import { InjectionToken } from '@angular/core';

export const PLATFORM_HOME = new InjectionToken<string>('');
export const PLATFORM_API = new InjectionToken<string>('');
export const PLATFORM_VERSION = new InjectionToken<string>('');
export const APP_NAME = new InjectionToken<string>('');
export const APP_LOGO = new InjectionToken<string>('');
export const API_AUTH_TOKEN = new InjectionToken<string>('');

export const SSO_API_URL = new InjectionToken<string>('url to the sso api');
export const IDS_API_URL = new InjectionToken<string>('url to the ids api');
export const APP_API_URL = new InjectionToken<string>('url to the ids app api');

export const WINDOW = new InjectionToken<Window>('Window Token');
export const LOCATION = new InjectionToken<Location>('Location Token');

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  private loaderSubject = new BehaviorSubject<boolean>(false);
  loaderVisible$ = this.loaderSubject.asObservable();
  private showCount = 0;
  constructor() {}

  /**
   * Show the spinner
   */
  show() {
    this.showCount++;
    this.loaderSubject.next(true);
  }

  /**
   * Hide the spinner (when appropriate)
   * @param force true to force the spinner to hide.
   */
  hide(force: boolean = false) {
    this.showCount = force ? 0 : Math.max(0, this.showCount - 1);
    this.loaderSubject.next(this.showCount > 0);
  }
}

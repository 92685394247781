export interface IGroupAssocRequest {
  groupId?: string;
}

export class GroupAssocRequest implements IGroupAssocRequest {
  constructor(groupId: string) {
    this.groupId = groupId;
  }
  groupId: string;
}

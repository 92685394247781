import { Action, createReducer, on } from '@ngrx/store';
import { GroupResponse } from '@shared/models/group-response.model';
import { GroupActions } from '../actions';


export const groupFeatureKey = 'group';

export interface GroupState {
  selectedGroup?: GroupResponse;
  loaded: boolean;
  groups?: GroupResponse[];
}

export const initialState: GroupState = {
  selectedGroup: null,
  loaded: false,
  groups: [],
};

const groupReducer = createReducer(
  initialState,
  on(GroupActions.GET_GROUPS, state => ({
    ...state
  })),
  on(GroupActions.LOAD_GROUPS, state => ({
    ...state
  })),
  on(GroupActions.GROUPS_LOADED, (state, { groups }) => {
    return {
      ...state,
      loaded: true,
      groups
    };
  }),
  on(GroupActions.GROUP_SELECTED, (state, { group }) => ({
    ...state,
    selectedGroup: group
  })),
  on(GroupActions.GROUP_ADDED, (state) => ({ ...state, loaded: false })),
  on(GroupActions.GROUP_MODIFIED, (state) => ({ ...state, loaded: false })),
  on(GroupActions.GROUP_DELETED, (state) => ({ ...state, loaded: false })),
  on(GroupActions.GROUP_ASSOC_CHANGE, (state) => ({ ...state, loaded: false })),
);

export function reducer(state: GroupState | undefined, action: Action) {
  return groupReducer(state, action);
}

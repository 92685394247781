import { createAction, props } from '@ngrx/store';
import { GroupResponse } from '@shared/models/group-response.model';
import { UsersFullResponse } from '@shared/models/users-full-response.model';

export const GET_GROUPS = createAction('[Get Current User Groups from WS]');
export const LOAD_GROUPS = createAction('[Load Current User Groups]');
export const GROUPS_LOADED = createAction('[Current User Groups Loaded]', props<{ groups: GroupResponse[] }>());
export const FAILED = createAction('[Failure in Web Request]');
export const GROUP_SELECTED = createAction('[User Group Selected]', props<{ group: GroupResponse }>());
export const GROUP_MODIFIED = createAction('[Group Was Modified]', props<{ group: GroupResponse }>());
export const GROUP_ADDED = createAction('[Group Was Added]', props<{ group: GroupResponse }>());
export const GROUP_DELETED = createAction('[Group Was Deleted]', props<{ group: GroupResponse }>());
export const GROUP_ASSOC_CHANGE = createAction(
  '[Group Association Changed]',
  props<{ groupsAdded: GroupResponse[]; groupsRemoved: GroupResponse[]; user: UsersFullResponse }>()
);

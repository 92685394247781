import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { permissionFeatureKey, PermissionState } from '../reducers/permission.reducer';

const userStateSelector = createFeatureSelector<AppState, PermissionState>(permissionFeatureKey);

export const userPermissions = createSelector(
  userStateSelector,
  (state: PermissionState) => (state ? state.permissions : undefined)
);
export const userPermissionsLoaded = createSelector(
  userStateSelector,
  (state: PermissionState) => (state ? state.loaded : false)
);

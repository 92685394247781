import { Injectable, Inject } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { WINDOW, LOCATION } from '@shared/injections';

import { environment } from '@environments/environment';
import { LoaderService } from '@shared/services/loader.service';
import { LoginTrackingService } from '@app/auth/login-tracking.service';

@Injectable({
  providedIn: 'root',
})
export class InitialAuthService {
  constructor(
    private oauthService: OAuthService,
    private authConfig: AuthConfig,
    private loader: LoaderService,
    private loginTrackingService: LoginTrackingService,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(LOCATION) private readonly location: Location
  ) {}

  public logOut() {
    this.loader.show();

    const accessToken = this.oauthService.getAccessToken();

    if (accessToken) {
      this.oauthService.revokeTokenAndLogout().finally(() => {
        this.loginTrackingService.removeTracking();
        this.finalizeLogout();
      });
    } else {
      this.finalizeLogout();
    }
  }
  private finalizeLogout(base: string = environment.sso.logoutUrl) {
    this.oauthService.logOut(false);
    this.location.href = `${base}?resumeUrl=${encodeURIComponent(this.window.location.href)}`;
  }

  async initAuth(): Promise<any> {
    return new Promise((resolveFn, rejectFn) => {
      this.oauthService.configure(this.authConfig);
      this.oauthService.setupAutomaticSilentRefresh();

      if (this.oauthService.hasValidAccessToken()) {
        resolveFn(() => {});
      } else {
        this.oauthService.tryLogin().then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            resolveFn(() => {});
          } else {
            const match = this.window.location?.hash.match(/^#(?<url>\/ids\/.+)/);
            this.oauthService.initCodeFlow(match?.groups?.url);
            rejectFn('Redirecting user to login page');
          }
        });
      }
    });
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { groupFeatureKey, GroupState } from '../reducers/group.reducer';

const groupStateSelector = createFeatureSelector<AppState, GroupState>(groupFeatureKey);

export const userGroups = createSelector(
  groupStateSelector,
  (state: GroupState) => (state ? state.groups : undefined)
);
export const userGroupsLoaded = createSelector(
  groupStateSelector,
  (state: GroupState) => (state ? state.loaded : false)
);
export const selectedGroup = createSelector(
  groupStateSelector,
  (state: GroupState) => (state ? state.selectedGroup : undefined)
);
